<template>
  <div class="view-login auth mb-5">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-5 mx-auto">
          <h1 class="text-center mb-5">
            <img src="../assets/logo-light.png" />
          </h1>
          <form class="card" @submit.prevent="submit">
            <div class="card-body p-5" v-if="!success">
              <h5 class="text-center mb-4 text-dark">Redefina a sua senha</h5>

              <form-group
                v-model="email"
                id="email"
                label="E-MAIL"
                type="email"
                disabled
                :errors="errors.email"
              />
              <form-group
                v-model="password"
                id="password"
                type="password"
                :is-login="true"
                label="NOVA SENHA"
                placeholder="Digite a nova senha"
                :errors="errors.password"
                @change="resetErrors"
              />

              <form-group
                v-model="password_confirm"
                id="password_confirm"
                type="password"
                :is-login="true"
                label="CONFIRMAÇÃO DE SENHA"
                placeholder="Digite a senha novamente"
                :errors="errors.password_confirm"
                @change="resetErrors"
              />

              <div class="text-center py-3">
                <hollow-button
                  :loading="loading"
                  title="REDEFINIR SENHA"
                  type="submit"
                  :disabled="!isValid"
                />
              </div>
            </div>
            <div class="card-body py-5" v-else>
              <div class="checked-circle">
                <i class="icon-check" />
              </div>
              <h5 class="text-center mb-4 text-dark">Solicitação concluída!</h5>
              <div class="text-center">
                <small class="text-secondary text-center">
                  Sua senha foi alterada com sucesso.
                </small>
              </div>
              <div class="text-center pt-5">
                <router-link
                  class="btn btn-outline-success btn-block"
                  :to="{ name: 'login' }"
                >
                  Fazer login
                </router-link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      email: this.$route.query.email,
      token: this.$route.query.token,
      password: "",
      password_confirm: "",
      errors: {
        email: null,
        password: null,
        password_confirm: null,
        non_field_errors: null
      },
      loading: false,
      success: false
    };
  },
  computed: {
    isValid() {
      return this.email && this.token && this.password && this.password_confirm;
    }
  },
  methods: {
    resetErrors() {
      const cleared = this.errors;
      for (let key in this.errors) {
        cleared[key] = null;
      }
      this.errors = cleared;
    },
    submit() {
      this.$message.hide();

      if (
        !this.email ||
        !this.email.length ||
        !/^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(
          this.email
        )
      ) {
        this.errors.email = ["e-mail inválido"];
        this.$message.error("E-mail inválido ou inexistente");
        return;
      }

      if (!this.token || !this.token.length) {
        this.$message.error("Token Inválido ou inexistente.");
        return;
      }

      if (this.password !== this.password_confirm) {
        this.errors.password_confirm = ["Senha não confere"];
        return;
      }

      this.loading = true;

      this.$store
        .dispatch("user/resetPassword", {
          password: this.password,
          email: this.email,
          token: this.token
        })
        .then(() => {
          this.success = true;
          setTimeout(() => {
            this.$router.replace("/");
          }, 3000);
        })
        .catch(error => {
          if (error.response.data && error.response.data.errors) {
            this.errors = error.response.data.errors;
            let msg = "";
            for (let key in this.errors) {
              msg += this.errors[key].join(". \n");
            }
            this.$message.error(msg);
          }

          if (this.errors.non_field_errors) {
            this.$message.error(this.errors.non_field_errors[0]);
          } else if (error.response.data.status === "notfound") {
            this.$message.error("Token inválido");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.view-login {
  width: 100%;
}
</style>
